import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Bootstrap DataTable
import { DataTablesModule } from 'angular-datatables';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { QRCodeModule } from 'angularx-qrcode';
import { JwtModule } from '@auth0/angular-jwt';
import { SharedModule } from 'primeng/api';
export function tokenGetter() {
  return localStorage.getItem("token");
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    QRCodeModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ["localhost:4200", "localhost:44366", 'api-anubandham.gujarat.gov.in', 'api.rojgarsetu.org', "grawsnet.orpgujarat.com", "grawsnetapi.orpgujarat.com", "sepapi.orpgujarat.com", "api.rojgarsetu.org", "rojgarsetu.org", 'anubandham.org', 'api.anubandham.org'],
        //disallowedRoutes: [""],
      }
    }),
    SharedModule,

    DataTablesModule,
    ToastrModule.forRoot(
      {
        timeOut: 1500,
        positionClass: 'toast-bottom-right',
        preventDuplicates: true,
      }
    ),
  ],
  providers: [
    {
     // provide: 'API_BASE_URL', useValue: 'https://localhost:44366/'
      // provide: 'API_BASE_URL', useValue: 'https://api-anubandham.gujarat.gov.in/'
      //provide: 'API_BASE_URL', useValue: 'https://api.rojgarsetu.org/'
      //provide: 'API_BASE_URL', useValue: 'https://sepapi.orpgujarat.com/'
      provide: 'API_BASE_URL', useValue: 'https://grawsnetapi.orpgujarat.com/'
    }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
